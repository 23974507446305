export function generateNewStudentId(existIds: string[], order = 1): string {
  const newId = order.toString().padStart(3, "0");
  if (existIds.includes(newId)) {
    return generateNewStudentId(existIds, order + 1);
  } else {
    return newId;
  }
}

export function breakDownReferencePath(studentRefPath: string) {
  if (!studentRefPath) {
    return {
      schoolId: "",
      classroomId: "",
      studentId: ""
    };
  }
  const splitted = studentRefPath.split("/");
  return {
    schoolId: splitted[1],
    classroomId: splitted[3],
    studentId: splitted[5]
  };
}
