
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import { Student } from "@/entities/student";
import { unitCollectionKey } from "@/entities/reflection";
import MsStudentPicker from "@/components/student/MsStudentPicker.vue";
import store from "@/store";
import "firebase/firestore";
import "firebase/functions";
import firebase from "firebase/app";

@Options({
  components: {
    MIcon,
    MBaseModal,
    MButton,
    MTextField,
    MsStudentPicker
  },
  emits: ["close"],
  props: {
    student: Object
  }
})
export default class MsEditModal extends Vue {
  student!: Student;
  selectedStudents: Student[] = [this.student];
  isValidTitle = false;
  enteredTitle = false;
  addingMultiple = false;
  units: {
    unitName: string;
    graphTitle: string;
    secondRowTitle: string;
    thirdRowTitle: string;
  }[] = [
    { unitName: "", graphTitle: "", secondRowTitle: "", thirdRowTitle: "" }
  ];

  get validUnits(): boolean {
    return this.isValidUnits();
  }

  get validStudentLength(): boolean {
    return this.selectedStudents.length > 0;
  }

  get validData() {
    return this.validUnits && this.validStudentLength;
  }

  close() {
    this.$emit("close");
  }

  isValidUnits(): boolean {
    if (!this.units.length) return false; // あり得ない
    if (this.units.length === 1) {
      return (
        Object.values(this.units[0]).length > 0 &&
        Object.values(this.units[0]).every(Boolean)
      );
    }
    return this.units.every(unit => {
      const vals = Object.values(unit);
      if (!vals.length) return false;

      // 完全に埋まっている or 完全に空の2通りを許可する
      let hasEmptyVal = false;
      let hasFilledVal = false;
      for (const val of vals) {
        if (val) {
          hasFilledVal = true;
        } else {
          hasEmptyVal = true;
        }
      }
      if (hasEmptyVal && hasFilledVal) return false;

      return true;
    });
  }

  updateSelectedStudents(students: Student[]) {
    this.selectedStudents = [...students];
  }

  async addUnit(
    {
      unitName,
      graphTitle,
      secondRowTitle,
      thirdRowTitle
    }: {
      unitName: string;
      graphTitle: string;
      secondRowTitle: string;
      thirdRowTitle: string;
    },
    studentRef: firebase.firestore.DocumentReference
  ) {
    if (!unitName) throw new Error("Invalid unit name");
    if (!graphTitle) throw new Error("Invalid graph title");
    if (!secondRowTitle) throw new Error("Invalid second row title");
    if (!thirdRowTitle) throw new Error("Invalid third row title");

    const newRef = studentRef.collection(unitCollectionKey).doc();
    await newRef.set({
      name: unitName,
      graphTitle,
      secondRowTitle,
      thirdRowTitle,
      createdAt: Math.floor(Date.now() / 1000),
      uid: newRef.id
    });
  }

  async commit() {
    if (!this.validData) return;
    for (const student of this.selectedStudents) {
      if (!student) {
        alert("情報が取得できない生徒が選択されています");
        return;
      }
    }
    try {
      store.commit("START_LOADING", "単元登録中...");
      await Promise.all(
        this.units.map(async unit =>
          Promise.all(
            this.selectedStudents.map(async (s, i) => {
              await new Promise(_ => setTimeout(_, 500 * i));
              return this.addUnit(
                {
                  unitName: unit.unitName,
                  graphTitle: unit.graphTitle,
                  secondRowTitle: unit.secondRowTitle,
                  thirdRowTitle: unit.thirdRowTitle
                },
                s.ref
              );
            })
          )
        )
      );
    } catch (e) {
      alert("単元の登録に失敗しました。");
      console.error(e);
    } finally {
      store.commit("END_LOADING");
      this.$router.go(0);
    }
  }

  created(): void {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      this.$emit("close");
    }
  }
}
