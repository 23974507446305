import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { breakDownReferencePath } from "@/utils/student";
import { Reflection, Unit } from "@/entities/reflection";

export type UnitResult = {
  result: {
    studentDocId: string;
    studentName: string;
    units: (Unit & { reflections: Reflection[] })[];
  };
  status: string;
  message: string;
};

type UnitResponseDataType = {
  res: { successfulResults: UnitResult[]; failedResults: UnitResult[] };
  status: string;
};

export async function getStudentsLearningCardInfo(
  studentRefs: firebase.firestore.DocumentReference[]
): Promise<UnitResponseDataType> {
  const pathFragments = studentRefs.map(ref =>
    breakDownReferencePath(ref.path)
  );

  const call = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("get_student_learning_card_infos", { timeout: 540 * 1000 });
  const res = await call({ pathFragments });
  if (res.data.status !== "success") {
    throw new Error("Unexpected error occurred");
  }
  return res.data;
}
