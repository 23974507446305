import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import { breakDownReferencePath } from "@/utils/student";

export type SuccessfulResult = {
  schoolId: string;
  studentId: string;
  classroomId: string;
  studentName: string;
  title: string;
  createdAt: number;
  isDone: boolean;
  isDoneAt: number;
  reflection: string;
};

export async function getStudentsTodos(
  studentRefs: firebase.firestore.DocumentReference[]
): Promise<any> {
  const brokenIds = studentRefs.map(ref => breakDownReferencePath(ref.path));

  const call = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("get_student_todos");
  const res = await call({ idMappings: brokenIds });

  // 成功の場合resにsuccessfulResults, failedResultsを持ったオブジェクト、statusに"success"と返る
  // 失敗の場合、resがnull、statusが"error"、およびエラーメッセージが返る
  return res.data;
}

export function formatTodoDataForCsv(rawData: SuccessfulResult[]) {
  const headerRow =
    [
      "学校ドキュメントID",
      "教室ドキュメントID",
      "生徒ドキュメントID",
      "生徒名",
      "TODOタイトル",
      "TODO作成時",
      "TODO実行有無",
      "TODO実行時",
      "振り返り（良かった、普通、悪い）"
    ].join(",") + "\n";

  const textToExport = rawData.reduce((acc, cur) => {
    const values = [];
    values.push(cur.schoolId);
    values.push(cur.classroomId);
    values.push(cur.studentId);
    values.push(cur.studentName);
    values.push(cur.title);
    values.push(dayjs.unix(cur.createdAt).format("YYYY/MM/DD HH:mm:ss"));
    values.push(cur.isDone ? "はい" : "いいえ");
    values.push(
      cur.isDone ? dayjs.unix(cur.isDoneAt).format("YYYY/MM/DD HH:mm:ss") : ""
    );
    values.push(
      cur.isDone
        ? cur.reflection === "best"
          ? "良かった"
          : cur.reflection === "good"
          ? "普通"
          : "悪い"
        : ""
    );
    const joined = values.join(",") + "\n";
    acc += joined;
    return acc;
  }, headerRow);

  return textToExport;
}
