
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import { Student } from "@/entities/student";
import store from "@/store";
import "firebase/firestore";
import "firebase/functions";
import {
  Unit,
  Reflection,
  reflectionCollectionKey,
  convertToReflection
} from "@/entities/reflection";
import MSLineChart from "@/components/student/MSLineChart.vue";
import { getUnixFromUid, sortReflectionsByUid } from "@/utils/learning_card";
import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");

@Options({
  components: {
    MIcon,
    MBaseModal,
    MButton,
    MTextField,
    MSLineChart,
    MTextArea
  },
  emits: ["close"],
  props: {
    student: Object,
    unit: Object,
    canEdit: Boolean
  }
})
export default class MsEditModal extends Vue {
  student!: Student;
  unit: Unit | null = null;
  reflections: Reflection[] = [];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    borderWidth: number;
    tension: number;
  }[] = [];
  labels: string[] = [];
  canEdit = false;
  unitName = "";
  graphTitle = "";
  secondRowTitle = "";
  thirdRowTitle = "";
  status: "display" | "teacherReflectionInput" = "display";
  validUnitReflection = false;
  unitReflection = "";
  isTeacherReflectionEmpty = false;
  min = 0;
  max = 100;

  close() {
    this.$emit("close");
  }

  formatDate(uid: string): string {
    const unix = getUnixFromUid(uid);
    return dayjs.unix(unix).format("MM/DD(ddd)");
  }

  async getReflections() {
    if (!this.unit) return [];
    const snapshot = await this.unit.ref
      .collection(reflectionCollectionKey)
      .get();
    return !snapshot.empty
      ? snapshot.docs
          .filter(doc => doc.exists && doc.data())
          .map(doc => convertToReflection(doc.data(), doc.ref))
      : [];
  }

  openReflectionInput() {
    this.unitReflection = !this.isTeacherReflectionEmpty
      ? ((this.unit as Unit).data.teacherReflection as string)
      : "";
    this.validUnitReflection = this.unitReflection.length > 0 ? true : false;
    this.status = "teacherReflectionInput";
  }

  clearUnitReflectionInput() {
    this.unitReflection = "";
    this.validUnitReflection = false;
    this.status = "display";
  }

  async registerUnitReflection() {
    if (!this.unit) return;
    if (!this.validUnitReflection) return;
    if (this.isTeacherReflectionEmpty && this.unitReflection.length === 0) {
      alert("振り返りが入力されていません。");
      return;
    }
    const text = this.isTeacherReflectionEmpty ? "登録" : "更新";
    try {
      store.commit("START_LOADING", `先生の振り返り${text}中...`);
      await this.unit.ref.update({ teacherReflection: this.unitReflection });
      store.commit("END_LOADING");
      this.$router.go(0);
    } catch (e) {
      alert(`振り返りの${text}に失敗しました。`);
      console.error(e);
      store.commit("END_LOADING");
    }
  }

  async created(): Promise<void> {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      this.$emit("close");
      return;
    }
    if (!this.unit) {
      alert("単元情報が取得できません。");
      this.$emit("close");
      return;
    }

    try {
      store.commit("START_LOADING", "情報取得中...");
      this.unitName = this.unit.data.name;
      this.graphTitle = this.unit.data.graphTitle || "記録(数値)";
      this.secondRowTitle =
        this.unit.data.secondRowTitle || "授業ごとの振り返り";
      this.thirdRowTitle = this.unit.data.thirdRowTitle || "自由入力";

      this.isTeacherReflectionEmpty = this.unit.data.teacherReflection
        ? false
        : true;
      const _reflections = await this.getReflections();
      this.reflections = sortReflectionsByUid(_reflections);

      if (this.reflections.length > 0) {
        const records = this.reflections.map(_ => +_.data.record);
        if (this.reflections.length > 1) {
          this.min = Math.min(...records) - 1;
          this.max = Math.max(...records) + 1;
        } else {
          const record = +this.reflections[0].data.record;
          this.min = record < 0 ? record - 1 : 0;
          this.max = record + 1;
        }
      }

      this.labels =
        this.reflections.length > 0
          ? this.reflections.map(_ =>
              dayjs.unix(getUnixFromUid(_.data.uid)).format("MM/DD")
            )
          : [];
      this.datasets =
        this.reflections.length > 0
          ? [
              {
                label: this.unitName,
                data: this.reflections.map(_ => _.data.record),
                borderColor: "rgb(75, 192, 192)",
                borderWidth: 2,
                tension: 0.1
              }
            ]
          : [];
      store.commit("END_LOADING");
    } catch (e) {
      alert("情報の取得に失敗しました。");
      console.error(e);
      store.commit("END_LOADING");
    }
  }
}
