
import {
  NewStudentData,
  getStudentsBasedOnRole,
  registerStudent
} from "@/api/student";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import { Classroom } from "@/entities/classroom";
import { StudentGrade, StudentProperty } from "@/entities/student";
import store from "@/store";
import { generateRandomPassword, validPassword } from "@/utils/password";
import { Options, Vue } from "vue-class-component";
import { validateCsvAndConvertToNewStudentDatas } from "@/utils/csv";
import { generateNewStudentId } from "@/utils/student";
import { validEmail } from "@/utils/email";
import { CustomProperty } from "@/entities/custom_property";
import { saveErrorLog } from "@/api/error";
import { Student } from "../../entities/student";
import { Getter } from "@/store/helper";
import { getClassRoomsBasedOnRole } from "@/api/classroom";
import { sortClassroomsById } from "@/utils/classroom";

type ClassSchedule = {
  weekday: string;
  start: string;
  end: string;
};

type EmailReceivers = {
  email: string;
};

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon,
    MSelectBox,
    MTextField,
    MTextArea
  },
  emits: ["close"],
  props: {
    classrooms: Array,
    customProperties: Array
  }
})
export default class MsAddModal extends Vue {
  name = "";
  enteredName = false;
  selectClassroomId = "";
  classrooms: Classroom[] = [];
  customProperties: CustomProperty[] = [];
  characteristic = "";
  studentId = "";
  studentLoginId = "";
  password = "";
  selectGrade: StudentGrade = "その他";
  grades: StudentGrade[] = [
    "小1",
    "小2",
    "小3",
    "小4",
    "小5",
    "小6",
    "中1",
    "中2",
    "中3",
    "高1",
    "高2",
    "高3",
    "その他"
  ];
  classSchedules: ClassSchedule[] = [];
  emailReceivers: EmailReceivers[] = [];
  propertyDatas: {
    property: CustomProperty;
    value: string;
  }[] = [];
  weekdays: string[] = ["月曜", "火曜", "水曜", "木曜", "金曜", "土曜", "日曜"];
  enteredEmail = [];
  validEmail = validEmail;

  @Getter("isServiceProvider") isServiceProvider!: boolean;

  get classRooms() {
    const classRooms = getClassRoomsBasedOnRole(
      this.isServiceProvider,
      this.classrooms,
      store.state.school!.ref.id
    );
    return sortClassroomsById(classRooms);
  }

  get selectClassroom(): Classroom | null {
    const matchClassrooms = this.classRooms.filter(
      item => item.ref.id === this.selectClassroomId
    );
    if (matchClassrooms.length === 0) {
      return null;
    }
    return matchClassrooms[0];
  }

  get schoolLoginId(): string {
    if (!store.state.school) {
      return "";
    }
    return store.state.school.data.id;
  }

  get defaultLoginId(): string {
    return `${this.schoolLoginId}-${this.studentId}`;
  }

  get validName() {
    return this.name.length > 0;
  }

  get validClassroom() {
    return this.selectClassroom !== null;
  }

  get validStudentId() {
    const students: Student[] = getStudentsBasedOnRole(
      this.isServiceProvider,
      store.state.students,
      store.state.school!.ref.id
    );
    if (students.map(item => item.data.id).includes(this.studentId)) {
      return false;
    }
    return /^[0-9a-zA-Z]{3,}$/.test(this.studentId);
  }

  get validstudentLoginId(): boolean {
    if (!this.studentLoginId) return this.validStudentId;
    return (
      /^[\x21-\x7E]+$/.test(this.studentLoginId) &&
      String(this.studentLoginId).length >= 5
    );
  }

  get validPassword() {
    return validPassword(this.password);
  }

  get validClassSchedules() {
    for (const schedule of this.classSchedules) {
      if (schedule.weekday.length === 0) {
        return false;
      }
      if (!schedule.start.match(/^\d{2}:\d{2}$/g)) {
        return false;
      }
      if (!schedule.end.match(/^\d{2}:\d{2}$/g)) {
        return false;
      }
    }

    return true;
  }

  get validEmailReceivers() {
    for (const emailReceiver of this.emailReceivers) {
      if (!emailReceiver.email) {
        return false;
      }
      if (!this.validEmail(emailReceiver.email)) {
        return false;
      }
    }
    return true;
  }

  get validPropertyDatas() {
    for (const data of this.propertyDatas) {
      if (
        data.property.data.type === "select" &&
        data.value &&
        !data.property.data.choices.includes(data.value)
      ) {
        return false;
      }
    }
    return true;
  }

  get validData() {
    return (
      this.validName &&
      this.validClassroom &&
      this.validStudentId &&
      this.validstudentLoginId &&
      this.validPassword &&
      this.validClassSchedules &&
      this.validEmailReceivers &&
      this.validPropertyDatas
    );
  }

  addClassSchedule() {
    this.classSchedules.push({
      weekday: "",
      start: "",
      end: ""
    });
  }

  addEmailReceiver() {
    this.emailReceivers.push({ email: "" });
  }

  deleteSchedule(index: number) {
    this.classSchedules.splice(index, 1);
  }

  deleteEmailReceiver(index: number) {
    this.emailReceivers.splice(index, 1);
  }

  allEnter() {
    this.enteredName = true;
  }

  async registerStudent() {
    this.allEnter();

    if (
      !this.validData ||
      !store.state.role ||
      !store.state.school ||
      !this.selectClassroom
    ) {
      return;
    }

    store.commit("START_LOADING", "生徒登録中...");

    const classScheduleText = this.classSchedules.map(
      schedule => `${schedule.weekday} ${schedule.start} ~ ${schedule.end}`
    );

    const emailReceivers = this.emailReceivers.map(
      emailReceiver => emailReceiver.email
    );

    const properties: StudentProperty[] = this.propertyDatas.map(data => {
      let value: string | number;
      if (data.property.data.type === "number") {
        value = Number(data.value);
      } else {
        value = data.value;
      }
      return {
        id: data.property.ref.id,
        value
      };
    });

    try {
      await registerStudent({
        classroomRef: this.selectClassroom.ref,
        id: this.studentId,
        loginId: this.studentLoginId.toString().trim(),
        name: this.name.trim(),
        grade: this.selectGrade,
        classSchedules: classScheduleText,
        emailReceivers,
        password: this.password,
        properties,
        characteristic: this.characteristic
      });
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`生徒の登録に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to register student"
      );
      return;
    }

    alert("生徒の登録が完了しました");

    this.$router.go(0);
  }

  async csvUpload(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    let datas: NewStudentData[];
    const students: Student[] = getStudentsBasedOnRole(
      this.isServiceProvider,
      store.state.students,
      store.state.school!.ref.id
    );

    try {
      datas = await validateCsvAndConvertToNewStudentDatas(
        event.target.files[0],
        this.classRooms,
        this.customProperties,
        students.map(s => s.data.id)
      );
    } catch (e) {
      alert(e);
      return;
    }
    if (datas.length === 0) return;
    const loginIds = datas.filter(d => d.loginId !== "").map(_ => _.loginId);
    const studentIds = datas.filter(d => d.id !== "").map(_ => _.id);
    if (loginIds.length > 0) {
      const uniqueElements = new Set(loginIds);
      if (loginIds.length !== uniqueElements.size) {
        alert("ログインIDに重複があります。");
        this.$router.go(0);
        return;
      }
    }
    // 普通はありえない
    if (studentIds.length > 0) {
      const uniqueElements = new Set(studentIds);
      if (studentIds.length !== uniqueElements.size) {
        alert("生徒の登録に失敗しました。");
        this.$router.go(0);
        return;
      }
    }

    store.commit("START_LOADING", "生徒登録中");
    const failed: string[] = [];
    try {
      const results = await Promise.allSettled(
        datas.map(data => registerStudent(data))
      );
      results.forEach((result, index) => {
        if (result.status === "rejected") {
          failed.push(datas[index].name);
          console.error(
            `${datas[index].name}で登録失敗。エラーは: ${result.reason
              .message ?? "unknown error"}`
          );
        }
      });
    } catch (e) {
      store.commit("END_LOADING");
      alert(`一部生徒の追加に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to register student"
      );
      return;
    }
    store.commit("END_LOADING");
    if (datas.length === failed.length) {
      alert("生徒の登録に失敗しました。");
      return;
    }
    if (failed.length > 0) {
      alert(
        `${failed.join(
          ","
        )} の生徒で登録に失敗しました。${"\n"}生徒IDやログインIDに被りがないか確認してください。`
      );
    } else {
      alert("生徒の登録に成功しました。");
    }
    this.$router.go(0);
  }

  close() {
    this.$emit("close");
  }

  created() {
    if (this.classRooms.length > 0) {
      this.selectClassroomId = this.classRooms[0].ref.id;
    }
    this.password = generateRandomPassword();

    this.studentId = generateNewStudentId(
      store.state.studentsOfSchool.map(item => item.data.id)
    );
    this.propertyDatas = this.customProperties.map(property => {
      return {
        property,
        value: ""
      };
    });
    this.studentLoginId = this.defaultLoginId;
  }
}
