
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import { Getter } from "@/store/helper";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MButton,
    MIcon
  },
  emits: [
    "onModalOpened",
    "export",
    "exportTodo",
    "exportLearningCard",
    "exportStudents",
    "group",
    "print",
    "message",
    "addReservation",
    "setStudentsRecess",
    "cancelStudentsRecess",
    "deleteStudents",
    "createSchoolAIStudent",
    "moveUpGradesForAll",
    "moveGradesDownForAll",
    "createSchoolAiUserForAll",
    "printMingakuLoginInfoForAll",
    "printSchoolAiLoginInfoForAll"
  ],
  props: {
    type: String,
    disabled: Boolean,
    conditionModalShowing: Boolean
  },
  watch: {
    conditionModalShowing(showing: boolean) {
      if (!showing) return;
      this.forceCloseModal();
    }
  }
})
export default class MTableConditionItem extends Vue {
  type = "";
  disabled = false;
  showModal = false;
  conditionModalShowing = false;

  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isAdminOfSchool") isAdminOfSchool!: boolean;
  @Getter("isSupervisorOfSchool") isSupervisorOfSchool!: boolean;
  @Getter("isEmployeeOfSchool") isEmployeeOfSchool!: boolean;

  get canEditStudent(): boolean {
    return (
      this.isAdmin ||
      this.isAdminOfSchool ||
      this.isSupervisorOfSchool ||
      this.isEmployeeOfSchool
    );
  }

  get title(): string {
    if (this.type === "selected") {
      return "選択中の生徒に実行";
    } else if (this.type === "all") {
      return "全生徒に実行";
    }
    return "";
  }

  changeShowModal() {
    if (this.disabled) {
      return;
    }
    this.showModal = !this.showModal;
    if (this.showModal) {
      this.$emit("onModalOpened");
    }
  }

  forceCloseModal() {
    this.showModal = false;
  }

  print(target: "mingaku" | "schoolAI") {
    this.showModal = false;
    this.$emit("print", target);
  }

  group() {
    this.showModal = false;
    this.$emit("group");
  }

  message() {
    this.showModal = false;
    this.$emit("message");
  }

  createSchoolAIStudent() {
    this.showModal = false;
    this.$emit("createSchoolAIStudent");
  }

  addReservation() {
    this.showModal = false;
    this.$emit("addReservation");
  }

  setStudentsRecess() {
    this.showModal = false;
    this.$emit("setStudentsRecess");
  }

  cancelStudentsRecess() {
    this.showModal = false;
    this.$emit("cancelStudentsRecess");
  }

  deleteStudents() {
    this.showModal = false;
    this.$emit("deleteStudents");
  }

  exportData() {
    this.showModal = false;
    this.$emit("export");
  }

  exportStudentData() {
    this.showModal = false;
    this.$emit("exportStudents");
  }

  exportTodoData() {
    this.showModal = false;
    this.$emit("exportTodo");
  }

  exportLearningCardData() {
    this.showModal = false;
    this.$emit("exportLearningCard");
  }

  // all系統
  moveUpGradesForAll() {
    this.showModal = false;
    this.$emit("moveUpGradesForAll");
  }

  moveGradesDownForAll() {
    this.showModal = false;
    this.$emit("moveGradesDownForAll");
  }

  createSchoolAiUserForAll() {
    this.showModal = false;
    this.$emit("createSchoolAiUserForAll");
  }

  printMingakuLoginInfoForAll() {
    this.showModal = false;
    this.$emit("printMingakuLoginInfoForAll");
  }

  printSchoolAiLoginInfoForAll() {
    this.showModal = false;
    this.$emit("printSchoolAiLoginInfoForAll");
  }
}
