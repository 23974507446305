import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")

  return (_ctx.isDiv)
    ? (_openBlock(), _createBlock("div", {
        key: 0,
        class: _ctx.dynamicClasses,
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2))
    : (!_ctx.link)
      ? (_openBlock(), _createBlock("button", {
          key: 1,
          class: _ctx.dynamicClasses,
          type: "button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2))
      : (_ctx.external)
        ? (_openBlock(), _createBlock("a", {
            key: 2,
            target: "_blank",
            href: _ctx.link,
            rel: "noopener noreferrer",
            class: _ctx.dynamicClasses
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 10, ["href"]))
        : (_openBlock(), _createBlock(_component_router_link, {
            key: 3,
            to: _ctx.link,
            class: _ctx.dynamicClasses
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 8, ["to", "class"]))
}