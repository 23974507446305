import { Reflection } from "@/entities/reflection";
import { UnitResult } from "@/api/learning_card";
import dayjs from "dayjs";

export function normalizeUid(date: string): string {
  // "2024-12-22-1"のようなデータが渡ってくる前提
  const parts = date.split("-").map(p => String(p));
  if (parts.length === 3) {
    return date;
  } else if (parts.length === 4) {
    return `${parts[0]}-${parts[1]}-${parts[2]}`;
  } else {
    throw new Error(`Invalid date format -- ${date}`);
  }
}

export function getUnixFromUid(date: string): number {
  // "2024-12-22-1"のようなデータが渡ってくる前提
  if (!date) return 0;
  const _date = (() => {
    try {
      return normalizeUid(date);
    } catch (_) {
      return "";
    }
  })();
  if (!_date) return 0;

  return dayjs(_date)
    .startOf("day")
    .unix();
}

export function sortReflectionsByUid(
  reflections: Reflection[],
  option = { asc: true }
) {
  if (!reflections || !Array.isArray(reflections) || !reflections.length) {
    return [];
  }
  const { asc } = option;
  const _reflections = [...reflections];
  return _reflections.sort((a, b) => {
    const diff = getUnixFromUid(a.data.uid) - getUnixFromUid(b.data.uid);
    if (diff !== 0) return asc ? diff : diff * -1;
    return a.data.updatedAt - b.data.updatedAt;
  });
}

function escapeCSVField(field: string) {
  if (field.includes('"') || field.includes(",") || field.includes("\n")) {
    return `"${field.replace(/"/g, '""')}"`;
  }
  return field;
}

export function formatReflectionsForCsv(
  data: UnitResult[],
  unitsHeader: string = [
    "生徒ID",
    "生徒名",
    "単元名",
    "単元作成日時",
    "自身の振り返り",
    "先生の振り返り",
    "AIの振り返り"
  ].join(","),
  reflectionsHeader: string = [
    "生徒ID",
    "生徒名",
    "単元名",
    "日付",
    "グラフのタイトル",
    "グラフの値",
    "2段目タイトル",
    "2段目の値",
    "3段目タイトル",
    "3段目の値"
  ].join(",")
) {
  const texts = data.reduce(
    (acc, cur) => {
      const { studentDocId, studentName, units } = cur.result;
      if (!units.length) return acc;
      units.forEach(unit => {
        const unitData: string[] = [];
        unitData.push(studentDocId);
        unitData.push(studentName);
        unitData.push(unit.data.name);
        unitData.push(
          dayjs.unix(unit.data.createdAt).format("YYYY/MM/DD HH:mm:ss")
        );
        unitData.push(
          unit.data.selfReflection
            ? escapeCSVField(unit.data.selfReflection)
            : "データがありません。"
        );
        unitData.push(
          unit.data.teacherReflection
            ? escapeCSVField(unit.data.teacherReflection)
            : "データがありません。"
        );
        unitData.push(
          unit.data.aiReflection
            ? escapeCSVField(unit.data.aiReflection)
            : "データがありません。"
        );
        acc.unitsData = acc.unitsData + "\n" + unitData.join(",");

        if (unit.reflections.length > 0) {
          const _reflections = sortReflectionsByUid(unit.reflections);
          _reflections.forEach(reflection => {
            const reflectionsData: (string | number)[] = [];
            reflectionsData.push(studentDocId);
            reflectionsData.push(studentName);
            reflectionsData.push(unit.data.name);
            reflectionsData.push(
              dayjs
                .unix(getUnixFromUid(reflection.data.uid))
                .format("YYYY/MM/DD")
            );
            reflectionsData.push(unit.data.graphTitle || "グラフ");
            reflectionsData.push(reflection.data.record);
            reflectionsData.push(unit.data.secondRowTitle || "振り返り");
            reflectionsData.push(escapeCSVField(reflection.data.reflection));
            reflectionsData.push(unit.data.thirdRowTitle || "自由入力");
            reflectionsData.push(escapeCSVField(reflection.data.description));

            acc.reflectionsData =
              acc.reflectionsData + "\n" + reflectionsData.join(",");
          });
        }
      });

      return acc;
    },
    {
      unitsData: unitsHeader,
      reflectionsData: reflectionsHeader
    }
  );

  return texts;
}
