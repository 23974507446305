import firebase from "firebase/app";

export const unitCollectionKey = "units";
export const reflectionCollectionKey = "reflections";

export type Unit = {
  ref: firebase.firestore.DocumentReference;
  data: UnitData;
};

export type UnitData = {
  name: string;
  graphTitle: string;
  secondRowTitle: string;
  thirdRowTitle: string;
  createdAt: number;
  uid: string;
  selfReflection?: string;
  teacherReflection?: string;
  aiReflection?: string;
};

export function convertToUnit(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Unit {
  return {
    ref,
    data: {
      name: data.name,
      graphTitle: data.graphTitle ?? "",
      secondRowTitle: data.secondRowTitle ?? "",
      thirdRowTitle: data.thirdRowTitle ?? "",
      createdAt: data.createdAt,
      uid: data.uid,
      selfReflection: data.selfReflection ?? "",
      teacherReflection: data.teacherReflection ?? "",
      aiReflection: data.aiReflection ?? ""
    }
  };
}

export type Reflection = {
  ref: firebase.firestore.DocumentReference;
  data: ReflectionData;
};

export type ReflectionData = {
  uid: string;
  record: number;
  reflection: string;
  description: string;
  createdAt: number;
  updatedAt: number;
};

export function convertToReflection(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Reflection {
  return {
    ref,
    data: {
      uid: data.uid,
      record: data.record,
      reflection: data.reflection,
      description: data.description,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt
    }
  };
}
